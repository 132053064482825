import {AfterContentInit, Component, Inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

declare var hbspt: any;

@Component({
  selector: 'app-frontpage',
  templateUrl: './frontpage.component.html',
  styleUrls: ['./frontpage.component.scss']
})
export class FrontpageComponent implements AfterContentInit {
  lang: string = 'en';
  langSettings = {
    no: {
      tld: 'no',
      portalId: '6269547',
      formId: '2e2db18e-1602-4798-89e9-b39762310300',
      title: 'Seater - Gratis Bordbooking til alle restauranter i Norge!',
      desc: 'Seater bruker kunstig intelligens (AI) for å bli kjent med gjestene dine. Det gjør at tjenerne dine kan tilby en mer personlig service og forbedre gjesteopplevelsen.',
      gID: 'GTM-KBJ9SBR',
      hbSessionDataSrc: 'https://meetings.hubspot.com/ech/15-min-seater-visning-av-esge?embed=true',
    },
    dk: {
      tld: 'dk',
      portalId: '6269547',
      formId: '4700db8d-3992-4f91-9c0d-f6f750a338e1',
      title: 'Seater - Bord booking, der lærer dine gæster at kende',
      desc: 'Seater bruger Artificial Intelligence (AI) til at lære dine gæster at kende. Det giver dine tjenere mulighed for, at yde en mere personlig service, og at løfte gæstens oplevelse.',
      gID: 'GTM-KWD5LWX',
      hbSessionDataSrc: 'https://meetings.quickorder.dk/meetings/jakoblilholm/15-minutters-seater-gennemgang?embed=true',
    },
    en: {
      tld: 'io',
      portalId: '6269547',
      formId: '91a5457b-4f86-4782-bb46-a6f2a22a174e',
      title: 'Seater - Table booking that gets to know your guests',
      desc: 'Seater uses Artificial Intelligence (AI) to get to know your guests. It allows your waiters  to provide a more personalized service and to enhance the guest experience.',
      gID: 'GTM-WHKJ3RL',
      hbSessionDataSrc: 'https://meetings.hubspot.com/ate/15-min-presentation?embed=true',
    }
  };

  constructor(
    private translate: TranslateService,
    public meta: Meta,
    @Inject(DOCUMENT) private document: Document,
    private title: Title
  ) {
    this.translate.setDefaultLang('en');
    this.setLang();
  }

  setLang() {
    const lang = window.location.host.split('.');
    const realLang = lang[lang.length - 1];

    switch (realLang) {
      case 'no':
        this.lang = 'no';
        break;
      case 'dk':
        this.lang = 'dk';
        break;
      default:
        this.lang = 'en';
        break;
    }
    this.translate.use(this.lang);
    this.setMetaTags();
    this.insertGoogleTags(this.langSettings[this.lang].gID);
  }

  setMetaTags() {
    const title = this.langSettings[this.lang].title;
    this.meta.updateTag({name: 'title', content: title});
    this.meta.updateTag({property: 'og:title', content: title});
    this.meta.updateTag({property: 'twitter:title', content: title},);
    this.title.setTitle(title);

    const desc = this.langSettings[this.lang].desc;
    this.meta.updateTag({name: 'description', content: desc});
    this.meta.updateTag({property: 'og:description', content: desc});
    this.meta.updateTag({property: 'twitter:description', content: desc});


    const url = 'www.seater.' + this.langSettings[this.lang].tld;
    this.meta.updateTag({property: 'og:url', content: url});
    this.meta.updateTag({property: 'twitter:url', content: url});
  }

  ngAfterContentInit() {
    hbspt.forms.create({
      portalId: this.langSettings[this.lang].portalId,
      formId: this.langSettings[this.lang].formId,
      target: '#form1'
    });
    hbspt.forms.create({
      portalId: this.langSettings[this.lang].portalId,
      formId: this.langSettings[this.lang].formId,
      target: '#form2'
    });
    this.insertDemoSession();
  }

  insertGoogleTags(gID) {
    /* tslint:disable */
    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      });
      let f = d.getElementsByTagName(s)[0];
      let j = d.createElement(s) as any;
      let dl = (l != 'dataLayer') ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gID);
    /* tslint:enable */
  }
  insertDemoSession() {
    const godefer2 = document.getElementsByTagName('link')[0];
    const hbSessionTag = document.createElement('script');
    hbSessionTag.type = 'text/javascript';
    hbSessionTag.crossOrigin = 'anonymous';
    hbSessionTag.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    godefer2.parentNode.insertBefore(hbSessionTag, godefer2);
  }
}
