<div class="header">
  <img loading="auto" class="seater-logo" alt="logo" src="../../../assets/images/logo.svg"/>
  <a class="sign-in-btn" [href]="'https://app.seater.' + langSettings[lang].tld">
    <svg width="10px" height="13px" viewBox="0 0 178 237" version="1.1" xmlns="http://www.w3.org/2000/svg" class="new-header-top-info-icon loginTop">
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="lock" fill="#B6987B" fill-rule="nonzero">
          <path d="M173.673622,122.819733 C170.788469,119.93865 167.287428,118.50054 163.165086,118.50054 L49.443211,118.50054 L49.443211,69.1259908 C49.443211,58.2204628 53.3068583,48.9124739 61.0330703,41.1971601 C68.7598236,33.4834676 78.0815017,29.6258107 89.0013518,29.6258107 C99.9201195,29.6258107 109.243421,33.4829271 116.966927,41.1971601 C124.693681,48.9124739 128.557328,58.2210033 128.557328,69.1259908 C128.557328,71.7995448 129.535281,74.1137066 131.490647,76.0679357 C133.450343,78.0227053 135.768856,79.0003603 138.44348,79.0003603 L148.33721,79.0003603 C151.013458,79.0003603 153.330889,78.0227053 155.290584,76.0679357 C157.242162,74.1137066 158.223904,71.7995448 158.223904,69.1259908 C158.223904,50.0938746 151.45075,33.81854 137.906607,20.2907998 C124.3603,6.76251904 108.057646,0 89.0013518,0 C69.9434342,0 53.642404,6.76251904 40.095014,20.2902593 C26.5497888,33.8163783 19.7771762,50.0933341 19.7771762,69.1254504 L19.7771762,118.5 L14.833288,118.5 C10.7141931,118.5 7.21098693,119.941352 4.32637568,122.819192 C1.44176442,125.697032 0,129.196378 0,133.311284 L0,222.187635 C0,226.304163 1.44230563,229.801887 4.32637568,232.681348 C7.21098693,235.559188 10.7141931,237 14.833288,237 L163.165086,237 C167.287428,237 170.791717,235.559188 173.673622,232.681348 C176.555527,229.801887 178,226.304163 178,222.187635 L178,133.311824 C178.002162,129.196918 176.559316,125.701356 173.673622,122.819733 Z"></path>
        </g>
      </g>
    </svg>
    <p class="new-header-top-text">{{ 'FRONTPAGE.LOGIN' | translate }}</p>
  </a>
</div>

<div class="hero-container">
  <div class="blur"></div>
  <div class="hero restricted">
    <div class="left-side">
      <div class="small-intro">{{ 'FRONTPAGE.WELCOME_SEATER' | translate }} <img loading="auto" class="lightning-icon" src="../../../assets/images/lightning-grey.svg" /></div>
      <div class="big-white">{{ 'FRONTPAGE.MONEY_FOR_TABLEBOOKING_DONT_LET_THEM_DO_IT' | translate }}</div>
      <div class="small-description">{{ 'FRONTPAGE.WOULD_YOU_STOP_PAYING_FOR_TABLEBOOKING_SIGN_UP' | translate }}</div>
      
      
      <div class="more-line">
        <button id="get-started-button1" class="action-button-normal" pageScroll href="#awesomePart">{{ 'FRONTPAGE.STOP_OVERPAYING' | translate }}</button>
      </div>
    
    </div>
    
    <img loading="auto" src="../../../assets/images/macbook-timeline.png" class="big-icon" />
  </div>

</div>
<div id="rooms" class="section rooms restricted">
  <div class="side">
    <div class="title">{{ 'FRONTPAGE.YOU_LAST_INVOICE_FOR_TABLEBOOKING' | translate }} <span>{{ 'FRONTPAGE.EVER' | translate }}</span></div>
    <div class="description">{{ 'FRONTPAGE.SEEATER_IS_FREE_SAME_FUNCTIONS' | translate }}<br><br><b>{{ 'FRONTPAGE.YOU_WILL_WITH_SEATER' | translate }}</b>
      
      <ul></ul>
      <li style="margin-bottom: 15px; margin-left: 30px;">{{ 'FRONTPAGE.SAVE_18000' | translate }}</li>
      <li style="margin-bottom: 15px; margin-left: 30px;">{{ 'FRONTPAGE.PERFORM_BETTER_SERVICE' | translate }}</li>
      <li style="margin-bottom: 15px; margin-left: 30px;">{{ 'FRONTPAGE.USE_EVERY_TABLE' | translate }}</li>
    </div>
    <button id="get-started-button2" class="action-button-normal" pageScroll href="#awesomePart">{{ 'FRONTPAGE.STOP_OVERPAYING' | translate }}</button>
  </div>
  
  <img loading="auto" src="../../../assets/images/rooms.png"/>
</div>

<div class="grey">
  
  <div class="section customer restricted">
    <img loading="auto" src="../../../assets/images/customer.svg"/>
    
    <div class="side">
      <div class="title">{{ 'FRONTPAGE.GOOD_SERVICE' | translate }}</div>
      <div class="description">{{ 'FRONTPAGE.WITH_CUSTOMERSEGMENT_YOU_WILL_PERFORM_PERSONAL_SERVICE' | translate }}</div>
    </div>
  
  </div>
  
  <div class="section time-line restricted">
    
    <div class="side">
      <div class="title">{{ 'FRONTPAGE.8_OF_10_HAVE_THIS_PROBLEM' | translate }}</div>
      <div class="description">{{ 'FRONTPAGE.NEW_SYSTEMS_USERS_ERROS' | translate }}<br><br><b>{{ 'FRONTPAGE.THATS_WHY' | translate }}</b> {{ 'FRONTPAGE.WE_USED_THE_BEST_DESIGNER_USE_IT_LIKE_YOUR_PHONE' | translate }}</div>
    </div>
    
    <img loading="auto" src="../../../assets/images/time-line.svg"/>
  
  </div>
</div>


<div id="first-input" class="floor-planning" style="padding-bottom: 100px;">
  
  <div class="restricted">
    <div class="title">{{ 'FRONTPAGE.PUT_YOUR_FOOT_DOWN' | translate }}</div>
    <span style="float: left; width: 100%; color: #fff; text-align: center; margin: 30px 0 0 0;">{{ 'FRONTPAGE.GET_A_NOTIFICATION_AS_THE_FIRST_ONE' | translate }}</span>
    <div class="hbspt-form" id="form1"></div>
    
    <!--<div class="developer">
      <div class="heavy">“Psst.. Jeg har gjort det gratis for evigt.”</div>
      <div class="light">Kristoffer Abell</div>
      <div class="developer-badge">
        <div class="image"></div>
        <div class="job-description">UDVIKLER</div>
      </div>
    </div>-->
  </div>
</div>


<div class="made-in-denmark restricted">
  <div class="powered-by">{{ 'FRONTPAGE.CREATED_IN_SECRET_BY_QO' | translate }}<img loading="auto" class="lightning-icon" src="../../../assets/images/lightning-gold.svg" /></div>
  
  <div class="title">{{ 'FRONTPAGE.INVESTMENTS_OF_10_MIL' | translate }}</div>
  
  <div class="description">{{ 'FRONTPAGE.FONDS_BELIVE_SEATER_WE_GOT_MONEY_LIKE_NO_OTHER_SYSTEM' | translate }}</div>
  
  <div class="products">
    <div class="column">
      <div class="product-box">
        <img loading="auto" src="../../../assets/images/lightning-red.svg" />
        <div class="product-label">{{ 'FRONTPAGE.POS' | translate }}</div>
      </div>
    </div>
    <div class="column">
      <div class="product-box red">
        <img loading="auto" src="../../../assets/images/seater-white.svg" />
      </div>
      
      <div class="product-box">
        <img loading="auto" src="../../../assets/images/quick-manager-red.svg" />
        <div class="product-label">{{ 'FRONTPAGE.MANAGER' | translate }}</div>
      </div>
    </div>
    <div class="column">
      <div class="product-box">
        <img loading="auto" src="../../../assets/images/quick-serve-red.svg" />
        <div class="product-label">{{ 'FRONTPAGE.SERVE' | translate }}</div>
      </div>
    </div>
  </div>
</div>


<div class="container white thin-border">
  <div class="showcase restricted">
    <div class="title">{{ 'FRONTPAGE.DO_YOUR_GUESTS' | translate }}<br><span>{{ 'FRONTPAGE.A_FAVOR' | translate }}</span></div>
    
    <div class="description">{{ 'FRONTPAGE.BOOKING_IN_30_SECS_NOTIFICATIONS_ON_SMS' | translate }}</div>
    
    <iframe
      class="video"
      src="https://www.youtube.com/embed/v5_MkQgq1FI?rel=0">
    </iframe>
  
  </div>
</div>
<div class="demosession restricted">
  <div class="title">{{ 'FRONTPAGE.GET_A_FREE_TOUR' | translate }}</div>
  <div class="meetings-iframe-container" [attr.data-src]="this.langSettings[this.lang].hbSessionDataSrc"></div>
</div>

<div class="key-features restricted">
  <div class="title">{{ 'FRONTPAGE.MONEY_FOR_TABLEBOOKING' | translate }}<br><span>{{ 'FRONTPAGE.DONT_LET_THEM_DO_IT' | translate }}</span></div>
  <div class="description">{{ 'FRONTPAGE.WHY_PAY_WHEN_YOU_CAN_GET_IT_FREE' | translate }}</div>
  
  <div class="images">
    
    <div class="image">
      <img loading="auto" src="../../../assets/images/segments-image.png"/>
      <div class="text">{{ 'FRONTPAGE.CREATE_CUSTOMERSEGMENTS' | translate }}</div>
    </div>
    
    <div class="image">
      <img loading="auto" src="../../../assets/images/planning-image.png"/>
      <div class="text">{{ 'FRONTPAGE.BUILD_YOUR_TABLE_SETUP' | translate }}</div>
    </div>
    
    <div class="image">
      <img loading="auto" src="../../../assets/images/timeline-image.png"/>
      <div class="text">{{ 'FRONTPAGE.TIMELINE_FOR_BOOKINGS' | translate }}</div>
    </div>
  
  </div>
</div>

<div id="last-input" class="floor-planning">
  <div class="title" id="awesomePart" #awesomePart>{{ 'FRONTPAGE.DECISION_OF_THE_YEAR' | translate }}</div>
  <div class="hbspt-form" id="form2"></div>
  
  <div class="argument">
    <div class="check-holder">
      <img loading="auto" src="../../../assets/images/check.svg"/>
    </div>
    
    <div class="text">{{ 'FRONTPAGE.FAST_AND_EASY_TO_YSE' | translate }}</div>
  </div>
  
  <div class="argument">
    <div class="check-holder">
      <img loading="auto" src="../../../assets/images/check.svg"/>
    </div>
    
    <div class="text">{{ 'FRONTPAGE.FREE_ACCESS_FOREVER' | translate }}</div>
  </div>
  
  <div class="argument" style="margin-bottom: 100px;">
    <div class="check-holder">
      <img loading="auto" src="../../../assets/images/check.svg"/>
    </div>
    
    <div class="text">{{ 'FRONTPAGE.A_PART_OF_QO' | translate }}</div>
  </div>
</div>


<div class="footer">
  <img loading="auto" class="seater-logo" alt="logo" src="../../../assets/images/logo.svg"/>
  <div class="powered-by">
    {{ 'FRONTPAGE.BY_QO' | translate }}
    <img loading="auto" class="lightning-icon" src="../../../assets/images/lightning-grey.svg" />
  </div>
  <div class="qo-info">
    {{'FRONTPAGE.QO_INFO' | translate}}
  </div>
</div>
